html {
  background: url(https://images-ext-2.discordapp.net/external/m-81TeIERTvN6QjCAsC3dFJyVPNat5u1VeHR1ASML6g/https/media.discordapp.net/attachments/630139747481812993/869038299442982952/ezgif.com-gif-maker_1.gif)
    no-repeat center center fixed;
  background-size: cover;
}
body {
  margin: 0;
  font-family: "Segoe UI", "Roboto";
  overflow: hidden;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(255, 0, 0);
  font-weight: bolder;
}
